// Navbar.jsx
import React, { useMemo, useState } from 'react';
import { Grid2, Select } from '@mui/material';
import DateRangePicker from 'core/libs/core-ui/components/Input/DateRangePickerWithInput';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import { LookUpExperience, LookUpExperiencer, LookUpHost } from '@utriper/core-ui';
import { EntityService } from 'core/api/services/lookUp';
import { useTranslation } from 'react-i18next';
// import PaymentStatus from 'core/libs/status/PaymentStatus/PaymentStatus';
import { PaymentStatus } from '@utriper/core';
import BookingOrigin from 'core/libs/types/BookingOrigin/BookingOrigin';
import PaymentMethods from 'core/libs/require/PaymentMethods';

const FiltersGrid = ({ dateSearch, setDateSearch, dateFilter, experienceSelected, handleSelectionChange }) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

    const [paymentStatus, setPaymentStatus] = useState('');
    const { t: translate, i18n } = useTranslation();
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    const statusOptions = useMemo(
        () =>
            PaymentStatus?.getIds().map((id) => {
                const translated = PaymentStatus.getTranslatedName(PaymentStatus.status, id, i18n.language);
                return { name: translated?.[0] ?? '', id };
            }),
        [PaymentStatus, i18n.language]
    );

    const bookingOriginOptions = useMemo(
        () =>
            BookingOrigin?.getIds().map((id) => {
                const translated = BookingOrigin.getTranslatedName(BookingOrigin.types, id, i18n.language);
                return { name: translated?.[0] ?? '', id };
            }),
        [BookingOrigin, i18n.language]
    );

    const paymentMethodsOptions = useMemo(
        () =>
            PaymentMethods?.getIds().map((id) => {
                const translated = PaymentMethods.getTranslatedName(PaymentMethods.payment_methods, id, i18n.language);
                return { name: translated ?? '', id };
            }),
        [PaymentMethods, i18n.language]
    );
    return (
        <Grid2 container display={'flex'} width={'full'} size={12} spacing={1} marginBottom={2}>
            <Grid2 size={{ xs: 12, sm: 3 }}>
                <DateRangePicker
                    onChange={(value) => {
                        if (value.startDate) {
                            setDateSearch(value);
                        } else {
                            setDateSearch({ startDate, endDate });
                        }
                    }}
                    value={dateFilter}
                    label={'Periodo'}
                    style={{
                        width: dateFilter ? (dateSearch.endDate ? '20rem' : '14rem') : '5rem',
                        marginRight: '1rem',
                    }}
                />
            </Grid2>
            <Grid2 size={{ xs: 6, md: 1.5 }}>
                <SelectComponent
                    values={statusOptions}
                    label="Status"
                    onFieldChange={(event) => {
                        setPaymentStatus(event.target.value);
                    }}
                    style={{ width: '8rem' }}
                />
            </Grid2>
            <Grid2 size={{ xs: 6, md: 1.5 }}>
                <SelectComponent label={'Fuente'} values={bookingOriginOptions} style={{ width: '8rem' }} />
            </Grid2>
            <Grid2 size={{ xs: 6, md: 1.5 }}>
                <LookUpHost label="Host" textFieldProps={{ style: { width: '8rem' } }} value={experienceSelected} entityService={EntityService} onChange={handleSelectionChange} />
            </Grid2>
            <Grid2 size={{ xs: 6, md: 1.5 }}>
                <LookUpExperience label="Actividad" textFieldProps={{ style: { width: '8rem' } }} value={experienceSelected} entityService={EntityService} onChange={handleSelectionChange} />
            </Grid2>
            <Grid2 size={{ xs: 6, md: 1.5 }}>
                <LookUpExperiencer label="Tour Operator" textFieldProps={{ style: { width: '8rem' } }} value={experienceSelected} entityService={EntityService} onChange={handleSelectionChange} />
            </Grid2>
            <Grid2 size={{ xs: 6, md: 1.5 }}>
                <SelectComponent
                    label={'Método de Pago'}
                    values={paymentMethodsOptions}
                    onChange={(ev) => {
                        setSelectedPaymentMethod(ev);
                    }}
                    style={{ width: '10rem' }}
                />
            </Grid2>
        </Grid2>
    );
};

export default FiltersGrid;
