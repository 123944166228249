import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    reportCard: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        borderColor: theme.palette.utriper.green,
        borderRadius: '4px',
        alignSelf: 'flex-end',
        paddingBlock: '20px',
        // marginInline: '8px'
    },
    cardValue: {
        fontWeight: '600',
        fontSize: '1.5rem',
    },
    greenCard: {
      background: `${theme.palette.utriper.green}b3`,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      borderColor: '#71ba6c',
      borderRadius: '4px',
      alignSelf: 'flex-end',
      paddingBlock: '8px',
    }
}));
