import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseBookingToModel, parseBookingToObject, parseBookingLogsToModel } from 'core/api/parser/booking';

const BookingRoutes = {
    get: (id) => `/api/v1.1.0/admin/booking/${id}`,
    getList: (options) => `/api/v1.1.0/admin/booking?email=${options.emailSearch}&bookingCode=${options.bookingCodeSearch}&viewAll=${options.viewAll === true ? true : false}&resellerBookingReference=${options.resellerBookingRefSearch}&${getPaging(options)}`,
    getBookingLogs: (id, options) => `/api/v1.1.0/admin/booking/${id}/logs?${getPaging(options)}`,
    // getExperienceList: (id,options) => `/api/v1.1.0/admin/contract/${id}/experience?${getPaging(options)}`,
    add: '/api/v1.1.0/admin/booking',
    update: (id) => `/api/v1.1.0/admin/booking/${id}`,
    // updateExperiences: (id) => `/api/v1.1.0/admin/contract/${id}`,
    // deleteExperiences: (id, expId) => `/api/v1.1.0/admin/contract/${id}/experience/${expId}`,
    uploadImage: (id) => `/api/v1.1.0/admin/booking/${id}/image`,
    // getQRCode: (id) => `/api/v1.1.0/admin/contract/${id}/qr`,
};

export const BookingService = {
    get: async (id) => {
        try {
            const { data } = await fetch(BookingRoutes.get(id), {
                method: Method.GET,
                authenticated: true,
            });
            return !data || !data.length ? {} : parseBookingToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },
    getList: async (options) => {
        try {
            const { data } = await fetch(BookingRoutes.getList(options), {
                method: Method.GET,
                authenticated: true,
            });
            // return !data || !data.length ? [] : data.map(parseContractToModel);
            return !data ? { bookings: [], total: 0 } : { total: data.total, bookings: data.bookings.map(parseBookingToModel) };
        } catch (err) {
            throw new Error(err);
        }
    },
    getBookingLogs: async (id, options) => {
        try {
            const { data } = await fetch(BookingRoutes.getBookingLogs(id, options), {
                method: Method.GET,
                authenticated: true,
            });
            // return !data || !data.length ? [] : data.map(parseContractToModel);
            return !data ? { bookingLogs: [], total: 0 } : { total: data.total, bookingLogs: data.bookingLogs.map(parseBookingLogsToModel) };
        } catch (err) {
            throw new Error(err);
        }
    },

    // getExperienceList: async (id,options) => {
    //     try {
    //         const { data } = await fetch(BookingRoutes.getExperienceList(id,options), {
    //             method: Method.GET,
    //             authenticated: true
    //         });
    //         // return !data || !data.length ? [] : data.map(parseContractToModel);
    //         return !data ? {experiences:[],total:0} : {total: data.total, experiences: data.experiences};
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },

    add: async (info) => {
        try {
            const { data } = await fetch(BookingRoutes.add, {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.status ? false : data; // parseContractToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(BookingRoutes.update(info.id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    // updateExperiences: async (id, info) => {
    //     try {
    //         const { data } = await fetch(BookingRoutes.updateExperiences(id), {
    //             method: Method.POST,
    //             authenticated: true,
    //             data: info,
    //         });
    //         return Array.isArray(data) ? data[0] : false;
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },

    // deleteExperiences: async (id, expId) => {
    //     try {
    //         const { data } = await fetch(BookingRoutes.deleteExperiences(id, expId), {
    //             method: Method.DELETE,
    //             authenticated: true,
    //         });
    //         return Array.isArray(data) ? data[0] : false;
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },

    uploadBookingPicture: async (id, image) => {
        try {
            const formData = new FormData();
            image = image.type && image.type !== '' ? image : image.slice(0, image.size, 'image/png');
            formData.append('images', image, image.name ?? `booking_${id}.png`);
            await fetch(BookingRoutes.uploadImage(id), {
                method: Method.POST,
                authenticated: true,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (err) {
            throw new Error(err);
        }
    },

    // getQRCode: async (id) => {
    //     try {
    //         const { data } = await fetch(BookingRoutes.getQRCode(id), {
    //             method: Method.GET,
    //             authenticated: true
    //         });
    //         return Array.isArray(data) ? data[0] : false;
    //     } catch (err) {
    //         throw new Error(err);
    //     }
    // },
};
