import { fetch, Method, getPaging } from 'core/api/fetch';
import { parseInsuranceProductsToModel, parseInsuranceToModel, parseInsuranceToObject } from '../parser/insurance';

const InsuranceRoutes = {
    get: (id) => `/api/v1.1.1/admin/insuranceProvider/${id}`,
    getListProducts: (id) => `/api/v1.1.1/admin/insuranceProvider/${id}`,
    getList: (options) => `/api/v1.1.1/admin/InsuranceProvider?name=${options.nameSearch}&${getPaging(options)}`,
    getListInsurances: () => `/api/v1.1.1/admin/InsuranceProvider`,
};

export const InsuranceService = {
    get: async (id) => {
        try {
            const { data } = await fetch(InsuranceRoutes.get(id), {
                method: Method.GET,
                authenticated: true,
            });
            return !data || !data.length ? {} : parseInsuranceToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },
    getList: async (options) => {
        try {
            const { data } = await fetch(InsuranceRoutes.getList(options), {
                method: Method.GET,
                authenticated: true,
            });
            return !data
                ? { insurances: [], total: 0 }
                : {
                      total: data.total,
                      insurances: data.insurances.map(parseInsuranceToModel),
                  };
        } catch (err) {
            throw new Error(err);
        }
    },

    getListInsurance: async () => {
        try {
            const { data } = await fetch(InsuranceRoutes.getListInsurances(), {
                method: Method.GET,
                authenticated: true,
            });
            return !data
                ? { insurances: [], total: 0 }
                : {
                      total: data.total,
                      insurances: data.insurances.map(parseInsuranceToModel),
                  };
        } catch (err) {
            throw new Error(err);
        }
    },

    getListProducts: async (id) => {
        try {
            const { data } = await fetch(InsuranceRoutes.getListProducts(id), {
                method: Method.GET,
                authenticated: true,
            });
            // return !data || !data.length ? [] : data.map(parseContractToModel);
            return !data
                ? { products: [], total: 0 }
                : {
                      total: data.total,
                      products: data.products.map(parseInsuranceProductsToModel),
                  };
        } catch (err) {
            throw new Error(err);
        }
    },
};
