import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Grid2, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useInsurance } from "core/contexts/insurance";
import { useEffect} from "react";
import { BasicTable, CircularIndeterminate, Title } from "@utriper/core-ui";
import Layout from "components/Layout";
import { useStyles } from "./styles";


const InsuranceProductPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t: translate, i18n } = useTranslation();

    const { insuranceProducts, loading, getInsurancesProducts } = useInsurance();

    const locationParam = document.location.href.split('/');
    const insuranceIndex = locationParam[locationParam.length - 1];

    useEffect(() => {
        getInsurancesProducts(insuranceIndex);
    }, []);

    const columns = [
        { align: 'left', val: 'id', label: 'Id' },
        { val: 'name', label: 'Name' },
        { align: 'center', val: 'type', label: 'Type' },
        { align: 'center', val: 'price', label: 'Price' },
        { align: 'center', val: 'priceType', label: 'Price Type' },
        { align: 'center', val: 'badge', label: 'Badge' },
        { align: 'center', val: 'isUpgrade', label: 'Is Upgrade' },
    ];

    // const handlePageChange = (event, value) => {
    //     setPage(value);
    // };

    // const onClick = (event, id) => {
    //     history.push(`/insuranceProviderProduct/${id}`);
    // };

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Insurances Products'} />
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        <BasicTable columns={columns} values={insuranceProducts}/>
                        <br />
                        <Grid2 container alignItems="flex-end">
                            <Grid2 size={{ xs: 1, sm: 7 }}></Grid2>
                        </Grid2>
                    </>
                )}
            </div>
        </Layout>
    );
};

InsuranceProductPage.displayName = 'InsuranceProduct';

export default InsuranceProductPage;
