import React, { useEffect, useState } from 'react';
import { Button, useMediaQuery, Typography, Card, CardContent, Grid2, Select } from '@mui/material';
import Layout from 'components/Layout';
import ApexChart from 'react-apexcharts'; // Asegúrate de instalarlo con `npm install react-apexcharts`
import { useStyles } from './styles';
import Chart from 'react-apexcharts';
import FiltersGrid from '../ReportNav';
import { useTranslation } from 'react-i18next';

export const HostReport = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const classes = useStyles();
 const [experienceSelected, setExperienceSelected] = useState('');
  const { t:translate } = useTranslation()

  const handleSelectionChange = (selectedValue) => {
    setExperienceSelected(selectedValue);
};
    const optionsHorizontal = {
        chart: {
            type: 'bar', // Specifies that the chart will be a bar chart
            height: 350,
        },
        title: {
            text: `Comisión uTriper por canal`,
            align: 'left',
            style: {
                fontSize: '14px',
                color: '#000000',
                fontFamily: 'Segoe UI',
                fontWeight: 650,
            },
        },
        subtitle: {
            text: `COP$`,
            align: 'left',
            style: {
                fontSize: '12px',
                color: '#000000',
                fontFamily: 'Segoe UI',
                fontWeight: 500,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '80%',
            },
        },
        xaxis: {
            categories: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
        },
        labels: ['Tickets'],
        colors: ['#A6ba62'],
    };

    const data = [
        {
            name: 'Host',
            quantity: 5,
        },
        {
            name: 'Qr',
            quantity: 26,
        },
        {
            name: 'Manual',
            quantity: 2,
        },
        {
            name: 'Link Propio',
            quantity: 9,
        },
        {
            name: 'Marketplace',
            quantity: 9,
        },
    ];

    let names = [];
    let quantities = [];
    data.forEach(function (n) {
        names.push(n.name);
        quantities.push(n.quantity);
    });

    const options = {
        chart: {
            type: 'bar', // Specifies that the chart will be a bar chart
            height: 350,
        },
        title: {
            text: `Comisión uTriper por canal`,
            align: 'left',
            style: {
                fontSize: '14px',
                color: '#000000',
                fontFamily: 'Segoe UI',
                fontWeight: 650,
            },
        },
        subtitle: {
            text: `COP$`,
            align: 'left',
            style: {
                fontSize: '12px',
                color: '#000000',
                fontFamily: 'Segoe UI',
                fontWeight: 500,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true, // Can set to true for horizontal bars
                columnWidth: '100%',
            },
        },
        xaxis: {
            categories: ['Paseo en Lancha', 'Caminata por Lacar', 'Scuba Diving at the Sea', 'Paseo Cristo Redentor, Maracana y Pan de azucar', 'Hiking en Machu Pichu', 'Paseo en Lancha', 'Caminata por Lacar', 'Scuba Diving at the Sea', 'Paseo Cristo Redentor, Maracana y Pan de azucar', 'Hiking en Machu Pichu'],
        },
        labels: ['Tickets'],
        colors: ['#A6ba62'],
    };

    const graphOptions = {
      chart: {
          type: 'line',
          height: 350,
          toolbar: {
              show: false, // Oculta el menú superior del gráfico
          },
      },
      stroke: {
          curve: 'smooth', // Línea suavizada
          width: 2, // Grosor de la línea
          colors: ['#8BC34A'], // Color de la línea
      },
      markers: {
          size: 0, // Oculta los puntos en la línea
      },
      xaxis: {
          categories: ['1 Dec', '5 Dec', '9 Dec', '13 Dec', '17 Dec', '21 Dec', '25 Dec'], // Fechas del eje X
          labels: {
              style: {
                  fontSize: '12px',
                  fontWeight: 400,
                  colors: '#000', // Color del texto
              },
          },
          axisBorder: {
              color: '#e0e0e0', // Línea del eje X
          },
          tickPlacement: 'on',
      },
      yaxis: {
          labels: {
              style: {
                  fontSize: '12px',
                  fontWeight: 400,
                  colors: '#000', // Color del texto
              },
              formatter: (value) => {
                  return value >= 1000 ? `${(value / 1000).toFixed(1)}K` : value; // Formateo de valores (ej. 1.5K)
              },
          },
          axisBorder: {
              show: false, // Sin borde en el eje Y
          },
          tickAmount: 5, // Cantidad de marcas en el eje Y
      },
      grid: {
          show: true, // Mostrar líneas de la cuadrícula
          borderColor: '#e0e0e0', // Color de la cuadrícula
          strokeDashArray: 4, // Estilo de las líneas de la cuadrícula
      },
      tooltip: {
          enabled: true, // Habilitar tooltip al pasar el mouse
          x: {
              show: true, // Mostrar la categoría en el tooltip
          },
          y: {
              formatter: (val) => `${val} tickets`, // Formateo del tooltip
          },
      },
    };
  

    const seriesHorizontal = [
        {
            name: 'Days',
            data: [200, 160, 150, 155, 120, 70, 150],
        },
    ];

    const series = [
        {
            name: 'Sales',
            data: [89, 78, 50, 50, 49, 45, 45, 40, 30, 22, 12], // Data for the bar chart
        },
    ];
    const graphs = [
      {
          name: 'Evolucion de reservas',
          data: [10,100,200,180,250,1000], // Data for the bar chart
      },
  ];


    return (
        <div style={{ marginBlock: '1rem', marginLeft: '1rem' }}>
            
            <Grid2 container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2.5rem' }} size={{ xs: 12, md: 10 }} spacing={2}>
                <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                    <span style={{ color: 'gray', fontSize: '14px' }}>Tickets</span>
                    <span className={classes.cardValue}>1,163</span>
                </Grid2>
                <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                    <span style={{ color: 'gray', fontSize: '14px' }}>Monto Total</span>
                    <span className={classes.cardValue}>1,163</span>
                </Grid2>
                <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                    <span style={{ color: 'gray', fontSize: '14px' }}>Comisión</span>
                    <span className={classes.cardValue}>1,163</span>
                </Grid2>
            </Grid2>

            <Grid2 marginTop={'4rem'} container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2.5rem' }} size={12} spacing={2}>
                <Grid2 size={{ xs: 12, md: 3 }}>
                    <Chart
                        type="pie"
                        height={350}
                        series={quantities}
                        labels={{
                            show: false,
                            name: {
                                show: true,
                            },
                        }}
                        options={{
                            labels: names,

                            legend: {
                                show: true,
                                position: 'right',
                            },
                            colors: ['#00AB55', '#2D99FF', '#FFE700', '#826AF9'],
                            title: {
                                text: `Tickets por canal`,
                                align: 'left',
                                style: {
                                    fontSize: '14px',
                                    color: '#000000',
                                    fontFamily: 'Segoe UI',
                                    fontWeight: 650,
                                },
                            },
                            subtitle: {
                                text: `# de tickets`,
                                align: 'left',
                                style: {
                                    fontSize: '12px',
                                    color: '#000000',
                                    fontFamily: 'Segoe UI',
                                    fontWeight: 500,
                                },
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 3 }}>
                    <Chart
                        type="pie"
                        height={350}
                        series={quantities}
                        labels={{
                            show: false,
                            name: {
                                show: true,
                            },
                        }}
                        options={{
                            labels: names,

                            legend: {
                                show: true,
                                position: 'right',
                            },
                            colors: ['#00AB55', '#2D99FF', '#FFE700', '#826AF9'],
                            title: {
                                text: `Monto vendido por canal`,
                                align: 'left',
                                style: {
                                    fontSize: '14px',
                                    fontWeight: 650,
                                    color: '#000000',
                                    fontFamily: 'Segoe UI',
                                },
                            },
                            subtitle: {
                                text: `COP$`,
                                align: 'left',
                                style: {
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    color: '#000000',
                                    fontFamily: 'Segoe UI',
                                },
                            },
                        }}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 3 }}>
                    <Chart
                        type="pie"
                        height={350}
                        series={quantities}
                        labels={{
                            show: false,
                            name: {
                                show: true,
                            },
                        }}
                        options={{
                            labels: names,

                            legend: {
                                show: true,
                                position: 'right',
                            },
                            colors: ['#00AB55', '#2D99FF', '#FFE700', '#826AF9'],
                            title: {
                                text: `Comisión por canal`,
                                align: 'left',
                                style: {
                                    fontSize: '14px',
                                    color: '#000000',
                                    fontFamily: 'Segoe UI',
                                    fontWeight: 650,
                                },
                            },
                            subtitle: {
                                text: `COP$`,
                                align: 'left',
                                style: {
                                    fontSize: '12px',
                                    color: '#000000',
                                    fontFamily: 'Segoe UI',
                                    fontWeight: 500,
                                },
                            },
                        }}
                    />
                </Grid2>
            </Grid2>

            {/* HORIZONTAL BAR CHARTS */}

            <Grid2 marginTop={'4rem'} container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2.5rem' }} size={12} spacing={4}>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Chart
                        options={{
                            ...options,
                            title: {
                                ...options.title,
                                text: 'Tickets por Actividad',
                            },
                            subtitle: {
                                ...options.subtitle,
                                text: '# de tickets',
                            },
                        }}
                        series={series}
                        type="bar"
                        height={300}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Chart
                        options={{
                            ...options,
                            title: {
                                ...options.title,
                                text: 'Monto Vendido por Actividad',
                            },
                        }}
                        series={series}
                        type="bar"
                        height={300}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Chart
                        options={{
                            ...options,
                            title: {
                                ...options.title,
                                text: 'Margen Tour Operador por Actividad',
                            },
                        }}
                        series={series}
                        type="bar"
                        height={300}
                    />
                </Grid2>
            </Grid2>

            {/* GRAHPS CHARTS */}
            <Grid2 marginTop={'4rem'} container style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', margin: '2.5rem' }} size={12} spacing={4}>
                <Grid2 size={{ xs: 12, md: 4 }}>
                <Chart
                        options={{
                            ...graphOptions,
                            title: {
                                ...graphOptions.title,
                                text: 'Evolución de Reservas',
                            },
                            subtitle: {
                              ...graphOptions.subtitle,
                              text: '# de tickets',
                          },
                        }}
                        series={graphs}
                        type="line"
                        height={300}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Chart
                        options={{
                            ...graphOptions,
                            title: {
                                ...graphOptions.title,
                                text: 'Evolución de Monto Vendido',
                            },
                            subtitle: {
                              ...graphOptions.subtitle,
                              text: 'COP$',
                          },
                        }}
                        series={graphs}
                        type="line"
                        height={300}
                    />
                </Grid2>
            </Grid2>

            {/* HORIZONTAL BAR CHARTS 2*/}
            <Grid2 marginTop={'4rem'} container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2.5rem' }} size={12} spacing={4}>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Chart
                        options={{
                            ...options,
                            title: {
                                ...options.title,
                                text: 'Tickets por Actividad',
                            },
                            subtitle: {
                                ...options.subtitle,
                                text: '# de tickets',
                            },
                        }}
                        series={series}
                        type="bar"
                        height={300}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Chart
                        options={{
                            ...options,
                            title: {
                                ...options.title,
                                text: 'Monto Vendido por Actividad',
                            },
                        }}
                        series={series}
                        type="bar"
                        height={300}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 4 }}>
                    <Chart
                        options={{
                            ...options,
                            title: {
                                ...options.title,
                                text: 'Margen Tour Operador por Actividad',
                            },
                        }}
                        series={series}
                        type="bar"
                        height={300}
                    />
                </Grid2>
            </Grid2>

            {/* VERTICAL BAR CHARTS */}

            <Grid2 marginTop={'4rem'} container display={'flex'} size={12} spacing={4}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Chart
                        options={{
                            ...optionsHorizontal,
                            title: {
                                ...options.title,
                                text: `¿Que día se reservan las actividades?`,
                            },
                            subtitle: {
                                ...options.subtitle,
                                text: '# de tickets reservados por día',
                            },
                        }}
                        series={seriesHorizontal}
                        type="bar"
                        height={300}
                    />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Chart
                        options={{
                            ...optionsHorizontal,
                            title: {
                                ...options.title,
                                text: `¿Que día se realizan las actividades?`,
                            },
                            subtitle: {
                                ...options.subtitle,
                                text: '# de tickets reservados por día',
                            },
                        }}
                        series={seriesHorizontal}
                        type="bar"
                        height={300}
                    />
                </Grid2>
            </Grid2>

        </div>
    );
};
