module.exports = (function() {
    const PAYMENT_METHODS = require("../schemas/payment_methods.json");

    const getIdsFromNumber = (number) => {
        const binaryString = number.toString(2);
        const binaryArray = binaryString.split("");
        const binaryArrayReversed = binaryArray.reverse();
        const ids = getIds();
        const idsArray = [];
        binaryArrayReversed.forEach((bit, index) => {
            if (bit === "1") {
                idsArray.push(ids[index]);
            }
        });
        return idsArray;
    };

    const getIds = () => [...Object.values(PAYMENT_METHODS).map(policy => policy.id)];

    // autogenerate on load
    const payment_methods = {}
    Object.values(PAYMENT_METHODS).forEach(value => {
	    payment_methods[value.id] = value.xlat;
    });

    // FIXME: maybe check that id and language are valid?
    // how about merging these two functions?
    const getTranslatedName = (payment_methods, id, lang) => {
        let language = lang.substring(0, 2);
        if (language !== 'es' && language !== 'en') { language = 'en'; }
        return payment_methods[id][language].name
    };

    // const getTranslatedDescription = (id, language) => this.payment_methods[id][language].desc;

    const getTranslatedNameFromNumber = (payment_methods, number, language) => {
        const ids = getIdsFromNumber(number);
        const names = ids.map(id => getTranslatedName(payment_methods, id, language));
        return names;
    };

    return {
        payment_methods,
        PAYMENT_METHODS,
        getTranslatedName,
        getIdsFromNumber,
        getTranslatedNameFromNumber,
        getIds
        // getTranslatedDescription
    };

})();