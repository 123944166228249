import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid2, Button } from '@mui/material';

import { useBooking } from 'core/contexts/booking';

import Layout from 'components/Layout';
import SearchBox from 'core/libs/core-ui/components/SearchBox';
import Title from 'core/libs/core-ui/components/Title';
import BasicTable from 'core/libs/core-ui/components/Table';
import PaginationControlled from 'core/libs/core-ui/components/Pagination';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import { useDebounce } from 'core/libs/core-ui/utils/useDebounce';
import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import BookingStatus from 'core/libs/status/BookingStatus/BookingStatus';
import BookingOrigin from 'core/libs/types/BookingOrigin/BookingOrigin';

import { useStyles } from './styles';
import { CheckboxComponent, PreviewExperience } from '@utriper/core-ui';
import { PreviewService } from 'core/api/services/preview';
import { useTranslation } from 'react-i18next';
import { ExportForm, AlertDialog } from '@utriper/core-ui';
import { DataportService } from 'core/api/services/dataport';

const BookingsPage = () => {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { t: translate } = useTranslation();

    const { getBookings, bookings, loading, total } = useBooking();

    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);

    const [emailSearch, setEmailSearch] = useState('');
    const [searchFilter, setSearchFilter] = useState('');
    const [viewAll, setViewAll] = useState(false);
    const emailDebounce = useDebounce(emailSearch, 300);
    const bookingCodeDebounce = useDebounce(searchFilter, 300);
    const [orderBy, setOrderBy] = useState('ci_created_on');
    const [direction, setDirection] = useState('desc');
    const [page, setPage] = useState(1);
    const MAX_PAGE = 20;

    useEffect(() => {
        getBookings({
            emailSearch: emailDebounce,
            bookingCodeSearch: bookingCodeDebounce,
            viewAll,
            pager: {
                column: orderBy,
                direction,
                page,
                pageSize: MAX_PAGE,
            },
        });
    }, [page, orderBy, direction, emailDebounce, bookingCodeDebounce, viewAll]);

    
    const toggleOpenDialog = () => {
        setOpen(!open);
    };

    const onSubmit = (values) => {
        if (!values.fromDateTime || !values.toDateTime) {
            return;
        }

        const fromDateTime = new Date(values?.fromDateTime);
        const toDateTime = new Date(values?.toDateTime);
        const columns = values?.columns?.map((column) => {
            if (column?.name.length === 0 || typeof column?.name !== 'string') {
                return null;
            }

            const columnName = column?.name
                .split(' ')
                .map((word, index) => {
                    if (index === 0) return word.toLowerCase();

                    return word;
                })
                .join('');

            return {
                [columnName]: true,
            };
        });

        DataportService.exportBookings({
            fromDateTime: fromDateTime.toISOString(),
            toDateTime: toDateTime.toISOString(),
            columns,
        });
    };

    const prettifyBookings = (bookings = []) => {
        return bookings.map((booking) => {
            return {
                triper: `${booking.firstName} ${booking.lastName}`,
                statusString: BookingStatus.getLabel(booking.status),
                originString: BookingOrigin.getLabel(booking.originId),
                priceString: `${booking.currency} ${MoneyFormattedUtils(booking.price)}`,
                paidPriceString: `${booking.currency} ${MoneyFormattedUtils(booking.paidPrice)}`,
                commissionString: `${booking.currency} ${MoneyFormattedUtils(booking.commission)}`,
                createdOnString: DateFormattedUtils(booking.createdOn, null, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: booking.timezone || 'UTC',
                }),
                expId: <PreviewExperience id={booking.experienceDetailId} label="name" previewService={PreviewService} />,
                dateString: DateFormattedUtils(booking.dateUTC, null, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: booking.timezone || 'UTC',
                }),
                feeRateString: `${booking.feeRate}%`,
                ...booking,
            };
        });
    };

    const columns = [
        { align: 'left', val: 'id', label: 'Id', column: 'ci_id' },
        { align: 'left', val: 'code', label: 'Code', column: 'ci_code' },
        { val: 'triper', label: 'Triper', column: 'ci_first_name', style: { minWidth: '200px' } },
        { val: 'email', label: 'Email', column: 'ci_email' },
        { val: 'expId', label: 'ExpName', column: 'ci_experience_detail_id' },
        { val: 'mobilePhone', label: 'Mobile Phone', column: 'ci_mobile_phone' },
        { val: 'dateString', label: 'Date of Activity', column: 'ci_date_utc', style: { minWidth: '250px' } },
        { val: 'createdOnString', label: 'Created On', column: 'ci_created_on', style: { minWidth: '250px' } },
        { align: 'center', val: 'quantity', label: 'Quantity', column: 'ci_quantity' },
        { align: 'right', val: 'paidPriceString', label: 'Paid Amount', column: 'ci_paid_price', style: { minWidth: '150px' } },
        { align: 'right', val: 'priceString', label: 'Price', column: 'ci_price', style: { minWidth: '150px' } },
        { align: 'right', val: 'commissionString', label: 'Comission', column: 'ci_comission', style: { minWidth: '150px' } },
        { align: 'center', val: 'feeRateString', label: 'Fee Rate', column: 'ci_fee_rate', style: { minWidth: '150px' } },
        { align: 'center', val: 'paymentId', label: 'PaymentId', column: 'ci_payment_id' },
        { align: 'left', val: 'originString', label: 'Origin', column: 'ci_origin_id' },
        { align: 'center', val: 'contractId', label: 'ContractId', column: 'ci_contract_id' },
        { align: 'left', val: 'statusString', label: 'Status', column: 'ci_status' },
        { align: 'left', val: 'resellerBookingReference', label: 'Reseller Code', column: 'ci_reseller_booking_reference', style: { minWidth: '150px' } },
    ];

    const mobileColumns = [
        { align: 'left', val: 'id', label: 'Id', column: 'ci_id' },
        { val: 'code', label: 'Code', column: 'ci_code' },
        { val: 'email', label: 'Email', column: 'ci_email' },
        { val: 'expId', label: 'ExpName', column: 'ci_experience_detail_id' },
        { val: 'dateString', label: 'Date of Activity', column: 'ci_date_utc', style: { minWidth: '250px' } },
        { val: 'createdOnString', label: 'Created On', column: 'ci_created_on', style: { minWidth: '250px' } },
        { align: 'right', val: 'paidPriceString', label: 'Paid Amount', column: 'ci_paid_price', style: { minWidth: '150px' } },
        { align: 'right', val: 'priceString', label: 'Price', column: 'ci_price', style: { minWidth: '150px' } },
        { val: 'originString', label: 'Origin', column: 'ci_origin_id' },
        { align: 'left', val: 'statusString', label: 'Status', column: 'ci_status' },
        { align: 'left', val: 'resellerBookingReference', label: 'Reseller Code', column: 'ci_reseller_booking_reference', style: { minWidth: '200px' } },
    ];

    const handleSort = (column) => {
        if (orderBy === column) {
            setDirection(direction === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(column);
            setDirection('asc');
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const onChangeEmailSearch = (event) => {
        setEmailSearch(event.target.value);
    };

    const onChangeSearch = (event) => {
        setSearchFilter(event.target.value);
    };

    const onChangeViewAll = (event) => {
        setViewAll(event);
    };

    const onClick = (event, id) => {
        history.push(`/booking/${id}`);
    };

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Bookings'} />
            <div style={{ marginBottom: '25px' }}>
                <SearchBox onChange={onChangeEmailSearch} value={emailSearch} label={'Email'} style={{ width: '14rem', marginRight: '1rem' }} />
                <SearchBox onChange={onChangeSearch} value={searchFilter} label={'Code'} style={{ width: '7rem', marginRight: '1rem' }} />
                <CheckboxComponent value={viewAll} onChange={onChangeViewAll} label="View All" />
            </div>
            <div>
                <Grid2 container pl={'10px'} pb={'25px'}>
                    <Button variant="contained" onClick={toggleOpenDialog}>
                        {translate('bookings.exportButton')}
                    </Button>
                </Grid2>
            </div>
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        {/* <Button
              onClick={(e) => {
                history.push(`/booking/0`)
              }}
            >ADD</Button> */}
                        <BasicTable size="small" columns={!isSmall ? columns : mobileColumns} handleSort={handleSort} orderBy={orderBy} direction={direction} values={prettifyBookings(bookings)} onClick={onClick} />
                        <br />
                        <Grid2 container alignItems="flex-end">
                            <Grid2 size={{ xs: 1, sm: 7 }}></Grid2>
                            <Grid2 size={{ xs: 11, sm: 5 }}>{total / MAX_PAGE < 1 ? null : <PaginationControlled count={Math.ceil(total / MAX_PAGE)} page={page} onChange={handlePageChange} />}</Grid2>
                        </Grid2>
                    </>
                )}
            </div>
            <AlertDialog open={open} handleClose={toggleOpenDialog} hideActions dialogTitle={translate('bookings.exportCsv')} dialogContent={<ExportForm onSubmit={onSubmit} onClose={toggleOpenDialog} isRangePicker={true} />} />
        </Layout>
    );
};

BookingsPage.displayName = 'BookingsPage';

export default BookingsPage;
