import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Layout from 'components/Layout';

import CircularProgress from 'core/libs/core-ui/components/Progress';
// import BookingPageComponent from 'core/libs/core-ui/components/BookingPage';
import { BookingPageComponent } from '@utriper/core-ui';

import { DateFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';

import BookingStatus from 'core/libs/status/BookingStatus/BookingStatus';
import BookingLog from 'core/libs/types/BookingLog/BookingLog';

import { useBooking } from 'core/contexts/booking';
import { usePayment } from 'core/contexts/payment';

import { ScheduleService } from 'core/api/services/schedule';
import { PreviewService } from 'core/api/services/preview';

import { useStyles } from './styles';

const BookingPage = () => {
    const classes = useStyles();
    const { getBooking, booking, loading, setBooking, updateBooking, bookingLogs, getBookingLogs, setBookingLogs, totalBookingLogs } = useBooking();
    const { getPayment, payment, setPayment } = usePayment();

    const [originalBooking, setOriginalBooking] = useState({});
    const [canEdit, setCanEdit] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    const [page, setPage] = useState(1);
    const MAX_PAGE = 2;

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const locationParam = document.location.href.split('/');
    const bookingIndex = locationParam[locationParam.length - 1];

    useEffect(() => {
        setBooking({});
        if (bookingIndex == 0) {
            setCanEdit(true);
        } else {
            getBooking(bookingIndex);
        }
    }, []);

    useEffect(() => {
        if (!hasChanged) {
            setPayment({});
            if (booking.paymentId) {
                getPayment(booking.paymentId);
            }
            setBookingLogs([]);
            if (booking.id) {
                getBookingLogs(booking.id, {
                    pager: {
                        column: 'ci_created_on',
                        page,
                        pageSize: MAX_PAGE,
                    },
                });
            }
        }
        // We do this to be able to cancel and revert unsaved changes
        if (!originalBooking.id || originalBooking.id !== booking.id) {
            const auxBooking = {
                ...booking,
            };
            setOriginalBooking(auxBooking);
        }
    }, [booking]);

    useEffect(() => {
        if (booking.id) {
            getBookingLogs(booking.id, {
                pager: {
                    column: 'ci_created_on',
                    page,
                    pageSize: MAX_PAGE,
                },
            });
        }
    }, [page]);

    const onSave = async (values) => {
        try {
            // values = { experiencer: userInfo };
            const valuesClon = { ...values };
            const res = await updateBooking(valuesClon);
            if (res) {
                toast.success('Booking updated!', {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
            getBooking(bookingIndex);
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    const onCancel = () => {
        setBooking(originalBooking);
    };

    const columns = [
        { align: 'left', val: 'id', label: 'Id' },
        { align: 'left', val: 'formattedCreatedBy', label: 'Created By' },
        { val: 'formattedCreatedOn', label: 'Created On' },
        { align: 'center', val: 'formattedStatus', label: 'Status' },
        { align: 'center', val: 'formattedType', label: 'Type' },
        { align: 'center', val: 'formattedPreviousRecord', label: 'Previous' },
        { align: 'center', val: 'formattedNewRecord', label: 'After' },
        { align: 'center', val: 'additional', label: 'Additional' },
    ];
    const prettifyBookingLogs = (bookingLogs = []) => {
        return bookingLogs.map((log) => {
            let prevString = '';
            if (log.previousRecord) {
                const prev = JSON.parse(log.previousRecord);

                if (prev?.expDetail) {
                    delete prev.expDetail;
                }

                prevString = (
                    <ul>
                        {Object.keys(prev).map((key) => {
                            return (
                                <li>
                                    {key}: {prev[key]}
                                </li>
                            );
                        })}
                    </ul>
                );
            }
            let newString = '';
            if (log.newRecord) {
                const newR = JSON.parse(log.newRecord);

                if (newR?.expDetail) {
                    delete newR.expDetail;
                }

                newString = (
                    <ul>
                        {Object.keys(newR).map((key) => {
                            return (
                                <li>
                                    {key}: {newR[key]}
                                </li>
                            );
                        })}
                    </ul>
                );
            }
            return {
                formattedCreatedBy: parseInt(log.createdBy) === 0 ? 'system' : log.createdBy,
                formattedCreatedOn: DateFormattedUtils(log.createdOn),
                formattedType: BookingLog.getLabel(log.type),
                formattedStatus: BookingStatus.getLabel(log.status),
                formattedPreviousRecord: prevString,
                formattedNewRecord: newString,
                ...log,
            };
        });
    };

    return (
        <Layout contentClassName={classes.content}>
            {/* <Title value={'Booking'} /> */}
            <div>
                {loading ? (
                    <CircularProgress centered={true} />
                ) : (
                    // STARTS BOOKING INFO
                    <BookingPageComponent
                        booking={booking}
                        setBooking={setBooking}
                        payment={payment}
                        bookingLogs={prettifyBookingLogs(bookingLogs)}
                        bookingLogsOptions={{
                            columns,
                            page,
                            MAX_PAGE,
                            total: totalBookingLogs,
                            handlePageChange,
                        }}
                        showPayment={true}
                        showLogs={totalBookingLogs}
                        canEdit={true}
                        isEditable={true}
                        hasChanged={hasChanged}
                        setHasChanged={setHasChanged}
                        onSave={onSave}
                        onCancel={onCancel}
                        accionButtons={{
                            setDate: {
                                show: true,
                            },
                            setNoShow: {
                                show: true,
                            },
                            setCancel: {
                                show: true,
                            },
                        }}
                        getAvailability={ScheduleService.getAvailability}
                        previewService={PreviewService}
                    />
                    // ENDS BOOKING INFO
                )}
            </div>
        </Layout>
    );
};

BookingPage.displayName = 'BookingPage';

export default BookingPage;
