import { fetch, Method, getPaging } from 'core/api/fetch';
import {
  parseContractMemberToObject,
    parseContractToModel,
    parseContractToObject,
} from 'core/api/parser/contract';

const ContractRoutes = {
    get: (id) => `/api/v1.1.0/admin/contract/${id}`,
    getList: (options) =>
        `/api/v1.1.0/admin/contract?name=${options.nameSearch}&${getPaging(
            options
        )}`,
    getExperienceList: (id, options) =>
        `/api/v1.1.0/admin/contract/${id}/experience?${getPaging(options)}`,
    getContractSectionList: (id, options) => `/api/v1.1.0/admin/contract/${id}/section?${getPaging(options)}`,
    getBundleList: (id, options) =>
        `/api/v1.1.0/admin/contract/${id}/bundle?${getPaging(options)}`,
    add: '/api/v1.1.0/admin/contract',
    update: (id) => `/api/v1.1.0/admin/contract/${id}`,
    addContractSection: (id) => `/api/v1.1.0/admin/contract/${id}/section`,
    addContractSectionImage: (sectionId) => `/api/v1.1.0/admin/contract/section/${sectionId}/image`,
    updateContractSection: (id, contractSectionId) => `/api/v1.1.0/admin/contract/${id}/section/${contractSectionId}`,
    deleteContractSection:(id, contractSectionId) => `/api/v1.1.0/admin/contract/${id}/section/${contractSectionId}`,
    addExperiences: (id) => `/api/v1.1.0/admin/contract/${id}`,
    updateExperience: (id, expId) =>
        `/api/v1.1.0/admin/contract/${id}/experience/${expId}`,
    deleteExperiences: (id, expId) =>
        `/api/v1.1.0/admin/contract/${id}/experience/${expId}`,
    updateBundles: (id) => `/api/v1.1.0/admin/contract/${id}/bundle`,
    deleteBundles: (id, expId) =>
        `/api/v1.1.0/admin/contract/${id}/bundle/${expId}`,
    uploadImage: (id) => `/api/v1.1.0/admin/contract/${id}/image`,
    getQRCode: (id) => `/api/v1.1.0/admin/contract/${id}/qr`,
    getContractMemberList: (id,  options) => `/api/v1.1.0/admin/contract/${id}/participant?${getPaging(options)}`,
    addContractMember: (id) => `/api/v1.1.0/admin/contract/${id}/participant`,
    updateContractMember: (contractId, memberId) => `/api/v1.1.0/admin/contract/${contractId}/participant/${memberId}`,
    deleteContractMember: (contractId, memberId) => `/api/v1.1.0/admin/contract/${contractId}/participant/${memberId}`
};

export const ContractService = {
    get: async (id) => {
        try {
            const { data } = await fetch(ContractRoutes.get(id), {
                method: Method.GET,
                authenticated: true,
            });
            return !data || !data.length ? {} : parseContractToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },
    getList: async (options) => {
        try {
            const { data } = await fetch(ContractRoutes.getList(options), {
                method: Method.GET,
                authenticated: true,
            });
            // return !data || !data.length ? [] : data.map(parseContractToModel);
            return !data
                ? { contracts: [], total: 0 }
                : {
                      total: data.total,
                      contracts: data.contracts.map(parseContractToModel),
                  };
        } catch (err) {
            throw new Error(err);
        }
    },

    getExperienceList: async (id, options) => {
        try {
            const { data } = await fetch(
                ContractRoutes.getExperienceList(id, options),
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            // return !data || !data.length ? [] : data.map(parseContractToModel);
            return !data
                ? { experiences: [], total: 0 }
                : { total: data.total, experiences: data.experiences };
        } catch (err) {
            throw new Error(err);
        }
    },

    getBundleList: async (id, options) => {
        try {
            const { data } = await fetch(
                ContractRoutes.getBundleList(id, options),
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            return !data
                ? { experiences: [], total: 0 }
                : { total: data.total, bundle: data.bundle };
        } catch (err) {
            throw new Error(err);
        }
    },

    add: async (info) => {
        try {
            const { data } = await fetch(ContractRoutes.add, {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return !data || !data.length
                ? false
                : parseContractToObject(data[0]);
        } catch (err) {
            throw new Error(err);
        }
    },

    update: async (info) => {
        try {
            const { data } = await fetch(ContractRoutes.update(info.id), {
                method: Method.PATCH,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    getContractSectionList: async (id, options) => {
        try {
            const { data } = await fetch(
                ContractRoutes.getContractSectionList(id, options),
                {
                    method: Method.GET,
                    authenticated: true,
                }
            );
            return data;
        } catch (err) {
            throw new Error(err);
        }
    },

    addContractSection: async (id, info) => {
        try {
            const { data } = await fetch(ContractRoutes.addContractSection(id), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateContractSection: async (id, contractSectionId, values) => {
        try {
            const { data } = await fetch(
                ContractRoutes.updateContractSection(id, contractSectionId),
                {
                    method: Method.PATCH,
                    authenticated: true,
                    data: values,
                }
            );

            return Array.isArray(data) ? data[0] : false;
        } catch (error) {
            throw new Error(error);
        }
    },

    addContractSectionImage: async(sectionId, image) => {
      try {

        

        const formData = new FormData();
        formData.append('image', image);


        await fetch(ContractRoutes.addContractSectionImage(sectionId), {
            method: Method.POST,
            authenticated: true,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
        });

    } catch (err) {
        throw new Error(err);
    }
    },

    deleteContractSection: async (id, contractSectionId) => {
        try {
            const { data } = await fetch(
                ContractRoutes.deleteContractSection(id, contractSectionId),
                {
                    method: Method.DELETE,
                    authenticated: true,
                }
            );
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    addExperiences: async (id, info) => {
        try {
            const { data } = await fetch(ContractRoutes.addExperiences(id), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateExperience: async (id, expId, values) => {
        try {
            const { data } = await fetch(
                ContractRoutes.updateExperience(id, expId),
                {
                    method: Method.PATCH,
                    authenticated: true,
                    data: values,
                }
            );

            return Array.isArray(data) ? data[0] : false;
        } catch (error) {
            throw new Error(error);
        }
    },

    deleteExperiences: async (id, expId) => {
        try {
            const { data } = await fetch(
                ContractRoutes.deleteExperiences(id, expId),
                {
                    method: Method.DELETE,
                    authenticated: true,
                }
            );
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    updateBundles: async (id, info) => {
        try {
            const { data } = await fetch(ContractRoutes.updateBundles(id), {
                method: Method.POST,
                authenticated: true,
                data: info,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    deleteBundles: async (id, expId) => {
        try {
            const { data } = await fetch(
                ContractRoutes.deleteBundles(id, expId),
                {
                    method: Method.DELETE,
                    authenticated: true,
                }
            );
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    uploadContractPicture: async (id, image) => {
        try {
            const formData = new FormData();
            image =
                image.type && image.type !== ''
                    ? image
                    : image.slice(0, image.size, 'image/png');
            formData.append(
                'images',
                image,
                image.name ?? `contract_${id}.png`
            );
            await fetch(ContractRoutes.uploadImage(id), {
                method: Method.POST,
                authenticated: true,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } catch (err) {
            throw new Error(err);
        }
    },

    getQRCode: async (id) => {
        try {
            const { data } = await fetch(ContractRoutes.getQRCode(id), {
                method: Method.GET,
                authenticated: true,
            });
            return Array.isArray(data) ? data[0] : false;
        } catch (err) {
            throw new Error(err);
        }
    },

    getContractMemberList: async (id, options) => {
      try {
        const { data } = await fetch(
            ContractRoutes.getContractMemberList(id, options),
            {
                method: Method.GET,
                authenticated: true,
            }
        );

        return data.length ? data.map(parseContractMemberToObject) : []; 
      } catch (err) {
        throw new Error(err);
      } 
    },


    addContractMember: async (values, id) => {
      try {

        const { data } = await fetch(
          ContractRoutes.addContractMember(id),
          {
              method: Method.POST,
              authenticated: true,
              data: values
          }
        );
        return data.length? data[0] : false
      } catch (error) {
        throw new Error(error);
      }
    },

    updateContractMember: async (values, contractId, memberId) => {
      try {

        const { data } = await fetch(
          ContractRoutes.updateContractMember(contractId, memberId),
          {
              method: Method.PATCH,
              authenticated: true,
              data: values
          }
        );
        return data.length? data[0] : false
      } catch (error) {
        throw new Error(error);
      }
    },

    deleteContractMember: async (contractId, memberId) => {
      try {

        const { data } = await fetch(
          ContractRoutes.deleteContractMember(contractId, memberId),
          {
              method: Method.DELETE,
              authenticated: true,
          }
        );
        return data.length? data[0] : false
      } catch (error) {
        throw new Error(error);
      }
    }
};
