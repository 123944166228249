import { Grid2 } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'
import Chart from "react-apexcharts";

export const AppReport = () => {

  const classes = useStyles();
 const [experienceSelected, setExperienceSelected] = useState('');
  const { t:translate } = useTranslation()

  const handleSelectionChange = (selectedValue) => {
    setExperienceSelected(selectedValue);
};

  const optionsHorizontal = {
    chart: {
      type: 'bar', // Specifies that the chart will be a bar chart
      height: 350,
    },
    title: {
      text: `Comisión uTriper por canal`,
      align: 'left',
      style: {
        fontSize: '14px',
        color: '#000000',
        fontFamily: 'Segoe UI',
        fontWeight: 650
      }
    },
    subtitle: {
      text: `COP$`,
      align: 'left',
      style: {
        fontSize: '12px',
        color: '#000000',
        fontFamily: 'Segoe UI',
        fontWeight: 500
      }
    },
    plotOptions: {
      bar: {
        horizontal: false, 
        columnWidth: '80%',
      },
    },
    xaxis: {
      categories: ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes','Sabado', 'Domingo']
    },
    labels: ['Tickets'],
    colors:['#A6ba62']
  };

const data = [
  {
    name: "Natural",
    quantity: 5
  },
  {
    name: "Industrial",
    quantity: 26
  },
  {
    name: "Infrastructure",
    quantity: 2
  },
  {
    name: "Pollution",
    quantity: 9
  }
];

let names = [];
let quantities = [];
data.forEach(function (n) {
  names.push(n.name);
  quantities.push(n.quantity);
});


const options = {
  chart: {
    type: 'bar', // Specifies that the chart will be a bar chart
    height: 350,
  },
  title: {
    text: `Comisión uTriper por canal`,
    align: 'left',
    style: {
      fontSize: '14px',
      color: '#000000',
      fontFamily: 'Segoe UI',
      fontWeight: 650
    }
  },
  subtitle: {
    text: `COP$`,
    align: 'left',
    style: {
      fontSize: '12px',
      color: '#000000',
      fontFamily: 'Segoe UI',
      fontWeight: 500
    }
  },
  plotOptions: {
    bar: {
      horizontal: true, // Can set to true for horizontal bars
      columnWidth: '100%',
    },
  },
  xaxis: {
    categories: ['Paseo en Lancha', 'Caminata por Lacar', 'Scuba Diving at the Sea', 'Paseo Cristo Redentor, Maracana y Pan de azucar', 'Hiking en Machu Pichu','Paseo en Lancha', 'Caminata por Lacar', 'Scuba Diving at the Sea', 'Paseo Cristo Redentor, Maracana y Pan de azucar', 'Hiking en Machu Pichu']
  },
  labels: ['Tickets'],
  colors:['#71ba6c']
};
const seriesHorizontal = [
  {
    name: 'Days',
    data: [200,160,150,155,120,70,150], 
  },
];

const series = [
  {
    name: 'Sales',
    data: [89, 78, 50, 50, 49, 45, 45, 40, 30, 22, 12], // Data for the bar chart
  },
];

  return (
      <div style={{ marginBlock: '1rem', marginLeft: '1rem' }}>
          

          <Grid2 container display={'flex'} size={{ xs: 12, md: 10 }} marginTop={2} spacing={2}>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>Tickets</span>
                  <span className={classes.cardValue}>1,163</span>
              </Grid2>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>Monto Total</span>
                  <span className={classes.cardValue}>1,163</span>
              </Grid2>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>Monto Pagado</span>
                  <span className={classes.cardValue}>1,163</span>
              </Grid2>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>Costo Procesamiento de Pago</span>
                  <span className={classes.cardValue}>4,704,863</span>
              </Grid2>
          </Grid2>
          <Grid2 container marginTop={'1rem'} display={'flex'} size={{ xs: 12, md: 10 }} spacing={2}>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>Comisión Hosts</span>
                  <span className={classes.cardValue}>17,654,163</span>
              </Grid2>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>Impuestos uTriper</span>
                  <span className={classes.cardValue}>178,163</span>
              </Grid2>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>WhatsApp Cost</span>
                  <span className={classes.cardValue}>14,163</span>
              </Grid2>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>Gastos bancarios</span>
                  <span className={classes.cardValue}>0</span>
              </Grid2>
          </Grid2>
          <Grid2 container marginTop={'1rem'} display={'flex'} size={{ xs: 12, md: 10 }} spacing={2}>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>uTriper</span>
                  <span className={classes.cardValue}>7,654,163</span>
              </Grid2>
              <Grid2 className={classes.reportCard} border={2} size={{ xs: 6, md: 2.5 }}>
                  <span style={{ color: 'gray', fontSize: '14px' }}>Ingero Neto TO</span>
                  <span className={classes.cardValue}>17,455,654</span>
              </Grid2>
              <Grid2 className={classes.greenCard} border={2} size={{ xs: 12, md: 5 }}>
                  <span style={{ color: 'white', fontSize: '14px', fontWeight: 700 }}>Saldo TO</span>
                  <span className={classes.cardValue}>14,163</span>
              </Grid2>
          </Grid2>

          <Grid2 marginTop={'4rem'} container display={'flex'} size={12} spacing={2}>
              <Grid2 size={{ xs: 12, md: 3 }}>
                  <Chart
                      type="pie"
                      height={350}
                      series={quantities}
                      labels={{
                          show: false,
                          name: {
                              show: true,
                          },
                      }}
                      options={{
                          labels: names,

                          legend: {
                              show: true,
                              position: 'right',
                          },
                          colors: ['#00AB55', '#2D99FF', '#FFE700', '#826AF9'],
                          title: {
                              text: `Comisión uTriper por canal`,
                              align: 'left',
                              style: {
                                  fontSize: '14px',
                                  color: '#000000',
                                  fontFamily: 'Segoe UI',
                                  fontWeight: 650,
                              },
                          },
                          subtitle: {
                              text: `COP$`,
                              align: 'left',
                              style: {
                                  fontSize: '12px',
                                  color: '#000000',
                                  fontFamily: 'Segoe UI',
                                  fontWeight: 500,
                              },
                          },
                      }}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 3 }}>
                  <Chart
                      type="pie"
                      height={350}
                      series={quantities}
                      labels={{
                          show: false,
                          name: {
                              show: true,
                          },
                      }}
                      options={{
                          labels: names,

                          legend: {
                              show: true,
                              position: 'right',
                          },
                          colors: ['#00AB55', '#2D99FF', '#FFE700', '#826AF9'],
                          title: {
                              text: `Comisión uTriper por canal`,
                              align: 'left',
                              style: {
                                  fontSize: '14px',
                                  color: '#000000',
                                  fontFamily: 'Segoe UI',
                                  fontWeight: 650,
                              },
                          },
                          subtitle: {
                              text: `COP$`,
                              align: 'left',
                              style: {
                                  fontSize: '12px',
                                  color: '#000000',
                                  fontFamily: 'Segoe UI',
                                  fontWeight: 500,
                              },
                          },
                      }}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 3 }}>
                  <Chart
                      type="pie"
                      height={350}
                      series={quantities}
                      labels={{
                          show: false,
                          name: {
                              show: true,
                          },
                      }}
                      options={{
                          labels: names,

                          legend: {
                              show: true,
                              position: 'right',
                          },
                          colors: ['#00AB55', '#2D99FF', '#FFE700', '#826AF9'],
                          title: {
                              text: `Comisión uTriper por canal`,
                              align: 'left',
                              style: {
                                  fontSize: '14px',
                                  color: '#000000',
                                  fontFamily: 'Segoe UI',
                                  fontWeight: 650,
                              },
                          },
                          subtitle: {
                              text: `COP$`,
                              align: 'left',
                              style: {
                                  fontSize: '12px',
                                  color: '#000000',
                                  fontFamily: 'Segoe UI',
                                  fontWeight: 500,
                              },
                          },
                      }}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 3 }}>
                  <Chart
                      type="pie"
                      height={350}
                      series={quantities}
                      labels={{
                          show: false,
                          name: {
                              show: true,
                          },
                      }}
                      options={{
                          labels: names,

                          legend: {
                              show: true,
                              position: 'right',
                          },
                          colors: ['#00AB55', '#2D99FF', '#FFE700', '#826AF9'],
                          title: {
                              text: `Comisión uTriper por canal`,
                              align: 'left',
                              style: {
                                  fontSize: '14px',
                                  color: '#000000',
                                  fontFamily: 'Segoe UI',
                                  fontWeight: 650,
                              },
                          },
                          subtitle: {
                              text: `COP$`,
                              align: 'left',
                              style: {
                                  fontSize: '12px',
                                  color: '#000000',
                                  fontFamily: 'Segoe UI',
                                  fontWeight: 500,
                              },
                          },
                      }}
                  />
              </Grid2>
          </Grid2>

          {/* HORIZONTAL BAR CHARTS */}

          <Grid2 marginTop={'4rem'} container display={'flex'} size={12} spacing={4}>
              <Grid2 size={{ xs: 12, md: 4 }}>
                  <Chart
                      options={{
                          ...options,
                          title: {
                              ...options.title,
                              text: 'Tickets por Actividad',
                          },
                          subtitle: {
                              ...options.subtitle,
                              text: '# de tickets',
                          },
                      }}
                      series={series}
                      type="bar"
                      height={300}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                  <Chart
                      options={{
                          ...options,
                          title: {
                              ...options.title,
                              text: 'Monto Vendido por Actividad',
                          },
                      }}
                      series={series}
                      type="bar"
                      height={300}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                  <Chart
                      options={{
                          ...options,
                          title: {
                              ...options.title,
                              text: 'Margen Tour Operador por Actividad',
                          },
                      }}
                      series={series}
                      type="bar"
                      height={300}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                  <Chart
                      options={{
                          ...options,
                          title: {
                              ...options.title,
                              text: 'Tickets por Canal',
                          },
                          subtitle: {
                              ...options.subtitle,
                              text: '# de tickets',
                          },
                      }}
                      series={series}
                      type="bar"
                      height={300}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                  <Chart
                      options={{
                          ...options,
                          title: {
                              ...options.title,
                              text: 'Monto Vendido por Canal',
                          },
                      }}
                      series={series}
                      type="bar"
                      height={300}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                  <Chart
                      options={{
                          ...options,
                          title: {
                              ...options.title,
                              text: 'Margen Tour Operador por Canal',
                          },
                      }}
                      series={series}
                      type="bar"
                      height={300}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                  <Chart
                      options={{
                          ...options,
                          title: {
                              ...options.title,
                              text: 'Tickets por Vendedor',
                          },
                          subtitle: {
                              ...options.subtitle,
                              text: '# de tickets',
                          },
                      }}
                      series={series}
                      type="bar"
                      height={300}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                  <Chart
                      options={{
                          ...options,
                          title: {
                              ...options.title,
                              text: 'Monto Vendido por Vendedor',
                          },
                      }}
                      series={series}
                      type="bar"
                      height={300}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                  <Chart
                      options={{
                          ...options,
                          title: {
                              ...options.title,
                              text: 'Margen Tour Operador por Vendedor',
                          },
                      }}
                      series={series}
                      type="bar"
                      height={300}
                  />
              </Grid2>
          </Grid2>

          {/* VERTICAL BAR CHARTS */}

          <Grid2 marginTop={'4rem'} container display={'flex'} size={12} spacing={4}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                  <Chart
                      options={{
                          ...optionsHorizontal,
                          title: {
                              ...options.title,
                              text: `¿Que día se reservan las actividades?`,
                          },
                          subtitle: {
                              ...options.subtitle,
                              text: '# de tickets reservados por día',
                          },
                      }}
                      series={seriesHorizontal}
                      type="bar"
                      height={300}
                  />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                  <Chart
                      options={{
                          ...optionsHorizontal,
                          title: {
                              ...options.title,
                              text: `¿Que día se realizan las actividades?`,
                          },
                          subtitle: {
                              ...options.subtitle,
                              text: '# de tickets reservados por día',
                          },
                      }}
                      series={seriesHorizontal}
                      type="bar"
                      height={300}
                  />
              </Grid2>
          </Grid2>

          <Grid2 marginTop={'4rem'} container display={'flex'} size={12} spacing={4}></Grid2>
      </div>
  );
}


