import axios from 'axios';
import { getSession } from '../session';

const DataportRoutes = {
    exportBookings: '/api/v1.1.1/admin/booking/csv',
};

export const DataportService = {
    exportBookings: (info) => {
        const authData = getSession();
        axios
            .post(`${process.env.REACT_APP_API_URL}${DataportRoutes.exportBookings}`, info, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${authData}`,
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'bookings.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error(error);
            });
    },
};
