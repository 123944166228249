import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Divider, Grid2, Switch } from '@mui/material';
import { HostReport } from './components/HostReport';
import { AppReport } from './components/AppReport';
import FiltersGrid from './components/ReportNav';
import { useTranslation } from 'react-i18next';

const ReportsPage = () => {
    const classes = useStyles();

    const [showHost, setShowHost] = useState(false);
    const [experienceSelected, setExperienceSelected] = useState('');
    const { t: translate } = useTranslation();
    const [dateFilter, setDateFilter] = useState('');
    const [showDateRange, setShowDateRange] = useState(false);
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const initialPeriod = {
        startDate,
        endDate,
    };
    const [dateSearch, setDateSearch] = useState(initialPeriod);

    const handleSelectionChange = (selectedValue) => {
        setExperienceSelected(selectedValue);
    };

   


    useEffect(() => {
        if (dateSearch.startDate) {
            let dateString = dateSearch.startDate.toLocaleDateString('en-EN', {
                day: '2-digit',
                month: 'long',
            });
            if (dateSearch.endDate) {
                dateString += ` - ${dateSearch.endDate.toLocaleDateString('en-EN', {
                    day: '2-digit',
                    month: 'long',
                })}`;
            }
            setDateFilter(dateString);
        } else {
            setDateFilter('');
        }
    }, [dateSearch]);



    return (
        <Layout contentClassName={classes.content}>
            <Grid2 container alignItems={'center'} display={'flex'} gap={4}>
                <Title value={'Reports'} />
                <Grid2 display={'flex'} component="label" container alignItems="center">
                    <Grid2>App</Grid2>
                    <Grid2>
                        <Switch
                            checked={showHost}
                            onChange={() => {
                                setShowHost(!showHost);
                            }}
                            value="checked"
                        />
                    </Grid2>
                    <Grid2>Host</Grid2>
                </Grid2>
            </Grid2>
            <Grid2 container display={'flex'} width={'full'} size={12} marginBlock={1}>
                <FiltersGrid dateSearch={dateSearch} setDateSearch={setDateSearch} dateFilter={dateFilter} experienceSelected={experienceSelected} handleSelectionChange={handleSelectionChange} />
            </Grid2>
            <Grid2 size={12}>
                <Divider variant="middle" />
            </Grid2>

            {showHost ? <HostReport /> : <AppReport />}
        </Layout>
    );
};

ReportsPage.displayName = 'ReportsPage';

export default ReportsPage;
