export const parseInsuranceToModel = (insurance) => ({
    ...insurance,
  });

  export const parseInsuranceProductsToModel = (products) => ({
    ...products,
  });
  
  export const parseInsuranceToObject = (data) => ({
    ...data,
  });
