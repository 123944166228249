import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Grid2, Button } from '@mui/material';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';

import { useStyles } from './styles';
import { BasicTable, CircularIndeterminate, PaginationControlled, SearchBox } from '@utriper/core-ui';
import { useInsurance } from 'core/contexts/insurance';

const InsurancePage = () => {
    const { getInsurances, insurances, loading, total } = useInsurance();

    const classes = useStyles();
    const history = useHistory();

    const [nameSearch, setNameSearch] = useState('');

    const [page, setPage] = useState(1);
    const MAX_PAGE = 20;

    useEffect(() => {
        getInsurances({
            nameSearch,
            pager: {
                page,
                pageSize: MAX_PAGE,
            },
        });
    }, []);

    useEffect(() => {
        getInsurances({
            nameSearch,
            pager: {
                page,
                pageSize: MAX_PAGE,
            },
        });
    }, [page, nameSearch]);

    const columns = [
        { align: 'left', val: 'id', label: 'Id' },
        { val: 'name', label: 'Name' },
        { align: 'center', val: 'country', label: 'Country' },
    ];


    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const onChangeNameSearch = (event) => {
        setNameSearch(event.target.value);
    };

    const onClick = (event, id) => {
        history.push(`/insuranceProvider/${id}`);
    };

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Insurances'} />
            <div style={{ marginBottom: '25px' }}>
                <Grid2 container direction="row" justifyContent="space-between" alignItems="center">
                    <SearchBox onChange={onChangeNameSearch} value={nameSearch} label={'Name'} style={{ width: '14rem', marginRight: '1rem' }} />
                </Grid2>
            </div>
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        <BasicTable columns={columns} values={insurances} onClick={onClick} />
                        <br />
                        <Grid2 container alignItems="flex-end">
                            <Grid2 size={{ xs: 1, sm: 7 }}></Grid2>
                            <Grid2 size={{ xs: 11, sm: 5 }}>{total / MAX_PAGE < 1 ? null : <PaginationControlled count={Math.ceil(total / MAX_PAGE)} page={page} onChange={handlePageChange} />}</Grid2>
                        </Grid2>
                    </>
                )}
            </div>
        </Layout>
    );
};

InsurancePage.displayName = 'InsurancePage';

export default InsurancePage;
