import { InsuranceService } from 'core/api/services/insurance';
import React, { useContext, useState } from 'react';

const InsuranceContext = React.createContext(null);

export const InsuranceProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [insurances, setInsurances] = useState([]);
    const [insuranceProduct, setInsuranceProduct] = useState([]);
    const [insuranceProducts, setInsuranceProducts] = useState([]);

    const [total, setTotal] = useState([]);
    
     const getProduct = async (id) => {
            try {
                setLoading(true);
                const result = await InsuranceService.get(id);
                if (!result) {
                    throw new Error();
                }
                setInsuranceProduct(result);
                return true;
            } catch (err) {
                console.error(err);
                return false;
            } finally {
                setLoading(false);
            }
        };

    const getInsurancesProducts = async (id) => {
        try {
            setLoading(true);
            const result = await InsuranceService.getListProducts(id);

            if (!result) {
                throw new Error();
            }
            setInsuranceProducts(result.products);
            setTotal(result.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const getInsurances = async (options) => {
        try {
            setLoading(true);
            const result = await InsuranceService.getList(options);

            if (!result) {
                throw new Error();
            }
            setInsurances(result.insurances);
            setTotal(result.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    
    const getListInsures = async () => {
        try {
            setLoading(true);
            const result = await InsuranceService.getListInsurance();

            if (!result) {
                throw new Error();
            }
            setInsurances(result.insurances);
            setTotal(result.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <InsuranceContext.Provider
            value={{
                loading,
                total,
                insurances,
                setInsurances,
                insuranceProduct,
                insuranceProducts,
                setInsuranceProducts,
                setInsuranceProduct,
                getInsurancesProducts,
                getInsurances,
                getListInsures,
                getProduct
            }}>
            {children}
        </InsuranceContext.Provider>
    );
};

InsuranceProvider.displayName = 'InsuranceProvider';

export const useInsurance = () => useContext(InsuranceContext);
